var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-2"},[_vm._v(" A buffer is a solution containing a weak [A] and its [B] or a weak [C] and its [D]. A buffer solution [E]. ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" The point at which a buffer can no longer resist changes in pH in the presence of an acid or a base is called the [F]. ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" What is a valid way to make an ammonia/ammonium buffer for use in the laboratory? Mix equal volumes of [G]. ")]),_vm._l((_vm.letterOptions),function(letter){return _c('p',{key:letter.inputName,staticClass:"mb-2"},[_vm._v(" "+_vm._s(letter.text)+" "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","max-width":"300px"},attrs:{"items":_vm.optionsAToD,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}],null,true),model:{value:(_vm.inputs[letter.inputName]),callback:function ($$v) {_vm.$set(_vm.inputs, letter.inputName, $$v)},expression:"inputs[letter.inputName]"}})],1)}),_c('p',{staticClass:"mb-2"},[_vm._v(" [E]: "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","max-width":"300px"},attrs:{"items":_vm.optionsE,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [F]: "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","max-width":"300px"},attrs:{"items":_vm.optionsF,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [G]: "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","max-width":"300px"},attrs:{"items":_vm.optionsG,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input7),callback:function ($$v) {_vm.$set(_vm.inputs, "input7", $$v)},expression:"inputs.input7"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }